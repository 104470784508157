import TYPES from '@/types';

// Application
import PermissionsStatusSubscription
  from '@/modules/my-investment/permissions/status/application/subscriptions/permissions-status-subscription';
import GetPermissionsStatusService
  from '@/modules/my-investment/permissions/status/application/services/get-permissions-status-service';
import { Geolocation } from '@/modules/shared/domain/geolocation';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContractSavingsGeolocationViewModel {
  @Inject(TYPES.PERMISSIONS_STATUS_SUBSCRIPTION)
  readonly permissionStatusSubscription!: PermissionsStatusSubscription;

  @Inject(TYPES.GET_PERMISSIONS_STATUS_SERVICE)
  readonly getPermissionStatusService!: GetPermissionsStatusService;

  @Inject(TYPES.GEOLOCATION)
  readonly geolocation!: Geolocation;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  i18n_namespace = 'components.contract-savings.geolocation';

  small_screen: null | boolean = null;

  exists_step = false;

  step_name = 'on_boarding_kuspit_geolocation';

  investment_provider_id = '';

  investment_provider_name = 'kuspit';

  is_loading = true;

  private on_boarding_step: OnBoardingStepEntity = {
    id: '',
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  requestPermission() {
    return this.geolocation.getCurrentPosition();
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
    }
  };

  async saveStep(latitude: string, longitude: string) {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload = {
        latitude,
        longitude,
      };
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(
        'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
      );
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    this.verifyStep(steps);

    this.is_loading = false;
  }
}
