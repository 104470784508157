







































































import i18n from '@/vue-app/plugins/i18n';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsGeolocationViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-geolocation-view-model';

@Component({ name: 'ContractSavingsGeolocation' })
export default class ContractSavingsGeolocation extends Vue {
  contract_savings_geolocation_view_model = Vue.observable(
    new ContractSavingsGeolocationViewModel(),
  );

  @Watch('contract_savings_geolocation_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  async created() {
    this.contract_savings_geolocation_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.contract_savings_geolocation_view_model.initialize();

    this.$emit('loadingInfo', this.contract_savings_geolocation_view_model.is_loading);
  }

  async nextStep() {
    try {
      this.contract_savings_geolocation_view_model.is_loading = true;
      const coordinates = await this.contract_savings_geolocation_view_model.requestPermission();
      const save_step = await this.contract_savings_geolocation_view_model.saveStep(
        coordinates.latitude.toString(), coordinates.longitude.toString(),
      );
      if (save_step) {
        this.$emit('nextStep');
      }
    } catch {
      const message = `${i18n.t(`${this.contract_savings_geolocation_view_model.i18n_namespace}.error_get_location`)}`;
      this.contract_savings_geolocation_view_model.messageNotifier.showErrorNotification(message);
    } finally {
      this.contract_savings_geolocation_view_model.is_loading = false;
    }
  }
}

